import React, { Component } from 'react';
import axios from "axios";
import './App.css';


// const serverAdress = 'http://localhost:5000'

var serverAdress;
// axios.defaults.withCredentials = true
// axios.defaults.headers["Access-Control-Allow-Origin"] = 'caiag.kg'


class Stands extends Component {

    constructor(props) {
        super(props);
        this.state = {
            oblast: [],
            leshoz: [],
            forestry: [],
            block: [],
            cycle: [1, 2, 3, 4, 5],
            selectedOblast: '',
            selectedLeshoz: '',
            selectedForestry: '',
            selectedBlock: '',
            selectedForestryNum: '',
            selectedCycle: 'cycle',
            blockId: '',
            standEstimations: [],
            purpose: [],
            woodSpecies: [],
            standForestUse: [],
            forestComposition: [],
            actions: [],
            expositionAbbr: ['С', 'СВ', 'В', 'ЮВ', 'Ю', 'ЮЗ', 'З', 'СЗ', 'Р'],
            tillage: [],
            creationType: [],
            tillageActions: [],
            creationTypeActions: [],
            notSimpleAction: [],
            updateTrigger: 1,
            dictionary: {},
            current_leshoz: '',
            current_forestry: '',
            current_leshoz_id: '',
            total_forestry_stands: 0,
            total_forestry_area: 0,
            is_waiting: null,
            wood_volume: []
        };
        this.refineLeshoz = this.refineLeshoz.bind(this);
        this.refineForestry = this.refineForestry.bind(this);
        this.refineBlock = this.refineBlock.bind(this);
        this.refineCycle = this.refineCycle.bind(this);
        this.selectCycle = this.selectCycle.bind(this);
        this.getData = this.getData.bind(this);
        this.openWindowWithPost = this.openWindowWithPost.bind(this);
        this.generatePdf = this.generatePdf.bind(this);
        this.calc_area = this.calc_area.bind(this);
    }

    componentDidMount() {
        if (localStorage.getItem('selected_oblast') !== '') {
            this.setState({ selectedOblast: localStorage.getItem('selected_oblast') }, () => {
                this.refineLeshoz2(this.state.selectedOblast)
            })
        }
        if (localStorage.getItem('selected_leshoz') !== '') {
            this.setState({ selectedLeshoz: localStorage.getItem('selected_leshoz') }, () => {
                this.refineForestry2(this.state.selectedLeshoz)
            })
        }
        // if (localStorage.getItem('selected_forestry') !== '') {
        //     this.setState({ selectedForestry: localStorage.getItem('selected_forestry') }, () => {
        //         this.refineBlock2(this.state.selectedForestry)
        //     })
        // }
        if (localStorage.getItem('selected_block') !== '') {
            this.setState({ selectedBlock: localStorage.getItem('selected_block') })
        }
        if (localStorage.getItem('blockId') !== '') {
            this.setState({ blockId: localStorage.getItem('blockId') })
        }
        if (!window.lng) {
            window.lng = 'ru'
        }
        if (window.location.href.includes('https://forest.caiag.kg/')) {
            console.log('forest')
            serverAdress = 'https://forest.caiag.kg/' + window.lng + '/rent/taxdescr'
        }
        else if (window.location.href.includes('https://forestry.caiag.kg/')) {
            console.log('forestry')
            serverAdress = 'https://forestry.caiag.kg/' + window.lng + '/rent/taxdescr'
        }
        else {
            serverAdress = 'https://forest.caiag.kg/' + window.lng + '/rent/taxdescr'
        }
        // axios.get(serverAdress + '/getdictionary').then(response => {
        //     this.setState({ dictionary: response.data });
        // })
        fetch(serverAdress + '/getdictionary', { credentials: "same-origin" }).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({ dictionary: data })
        })
        // axios.get(serverAdress + '/getoblast', {
        // }).then(response => {
        //     this.setState({ oblast: [...this.state.oblast, ...response.data.result] });
        // })
        fetch(serverAdress + '/getoblast', { credentials: "same-origin" }).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({ oblast: [...this.state.oblast, ...data.result] })
        })

        // axios.get(serverAdress + '/getleshoz').then(response => {
        //     // this.setState({ leshoz: [...this.state.leshoz, ...response.data.result] })
        //     this.setState({ leshoz: response.data.result })
        // })

        fetch(serverAdress + '/getleshoz', { credentials: "same-origin" }).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({ leshoz: data.result })
        })
        axios.get(serverAdress + '/getwoodspecies').then(response => {
            this.setState({ woodSpecies: response.data.result });
        })
        axios.get(serverAdress + '/gettillagecreationtype').then(response => {
            this.setState({
                tillage: response.data.result.map(elem => {
                    return elem.actiontype_id
                })
            }, () => {
                this.setState(state => ({
                    notSimpleAction: state.tillage
                }))
            });
        })
        // axios.get(serverAdress + '/getcreationtype').then(response => {
        //     this.setState({
        //         creationType: response.data.result.map(elem => {
        //             return elem.actiontype_id
        //         })
        //     }, () => {
        //         this.setState(state => ({
        //             notSimpleAction: state.notSimpleAction.concat(state.creationType)
        //         }))
        //     });
        // })

    }

    calc_area(value) {
        const fixed = value.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0]
        return fixed
    }

    get_wood_volume(standestimation_id) {
        const result = this.state.wood_volume.filter(elem => elem.standestimation_id === standestimation_id)
        try {
            return result[0].supply
        } catch (error) {
            return ''
        }
    }

    refineLeshoz(event) {
        this.setState({ selectedOblast: event.target.value }, () => {
            localStorage.setItem('selected_oblast', this.state.selectedOblast)
        })

        // axios.get(serverAdress + '/refineleshoz?oblast_id=' + event.target.value).then(response => {
        //     this.setState({ leshoz: [...response.data.result], forestry: [], block: [] });
        // });

        fetch(serverAdress + '/refineleshoz?oblast_id=' + event.target.value, { credentials: "same-origin" }).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({ leshoz: [...data.result], forestry: [], block: [] })
        })
    }

    refineLeshoz2(oblast) {
        // axios.get(serverAdress + '/refineleshoz?oblast_id=' + oblast).then(response => {
        //     this.setState({ leshoz: [...response.data.result] });
        // });
        fetch(serverAdress + '/refineleshoz?oblast_id=' + oblast, { credentials: "same-origin" }).then((response) => {
            return response.json()
        }).then((data) => {
            this.setState({ leshoz: [...data.result] })
        })
    }

    refineForestry(event) {
        this.setState({ selectedLeshoz: event.target.value }, () => {
            localStorage.setItem('selected_leshoz', this.state.selectedLeshoz)
        })
        axios.get(serverAdress + '/refineforestry?leshoz_id=' + event.target.value).then(response => {
            this.setState({ forestry: [...response.data.result], block: [] });
        });
    }

    refineForestry2(leshoz) {
        if (leshoz) {
            if (leshoz.length > 3) {
                this.setState({ forestry: [] });
            }
            else {
                axios.get(serverAdress + '/refineforestry?leshoz_id=' + leshoz).then(response => {
                    this.setState({ forestry: [...response.data.result] });
                });
            }
        }
    }

    refineBlock(event) {
        this.setState({ selectedForestry: event.target.value }, () => {
            localStorage.setItem('selected_forestry', this.state.selectedForestry)
        })
        // axios.get(serverAdress + '/refineblock?forestry_id=' + event.target.value).then(response => {
        //     this.setState({ block: [...response.data.result] });
        // });
    }

    refineBlock2(forestry) {
        axios.get(serverAdress + '/refineblock?forestry_id=' + forestry).then(response => {
            this.setState({ block: [...response.data.result] });
        });
    }

    refineCycle(event) {
        this.setState({ selectedBlock: event.target.value, blockId: this.state.block[event.target.value - 1].gid }, () => {
            localStorage.setItem('selected_block', this.state.selectedBlock)
            localStorage.setItem('blockId', this.state.blockId)
        })
    }

    selectCycle(event) {
        this.setState({ selectedCycle: event.target.value })
    }

    getData() {
        console.log('forestry', this.state.selectedForestry, ' leshoz', this.state.selectedLeshoz.length, 'oblast ', this.state.selectedOblast)
        if (this.state.selectedForestry.length > 3 || this.state.selectedLeshoz.length > 3 || this.state.selectedOblast.length > 3 || this.state.selectedForestry.length === 0 || this.state.selectedLeshoz.length === 0 || this.state.selectedOblast.length === 0) return
        this.setState({ is_waiting: true })
        this.setState({ total_forestry_stands: 0, total_forestry_area: 0, standEstimations: [] })
        const selected_leshoz = this.state.selectedLeshoz
        const selected_forestry = this.state.selectedForestry
        const leshoz = this.state.leshoz.find(function (element) {
            return element.leshoz_id === parseInt(selected_leshoz)
        });
        const forestry = this.state.forestry.find(function (element) {
            return element.forestry_num === parseInt(selected_forestry)
        });
        this.setState({ current_leshoz: leshoz.leshoz, current_forestry: forestry.forestry.substring(3), current_leshoz_id: leshoz.leshoz_id })
        axios.get(serverAdress + '/getdatabyforestry?leshoz_id=' + localStorage.getItem('selected_leshoz') + '&forestry_num=' + localStorage.getItem('selected_forestry')).then(response => {
            this.setState({ standEstimations: [...response.data.standestimations], is_waiting: null })
        })
        axios.get('https://forest.caiag.kg/shapeload/stratum/calc-stands-supply?leshozId=' + localStorage.getItem('selected_leshoz') + '&forestryNum=' + localStorage.getItem('selected_forestry') + '&cycle=2').then(response => {
            this.setState({ wood_volume: [...response.data] })
        })
        axios.get(serverAdress + '/getpurpose').then(response => {
            this.setState({ purpose: [...this.state.purpose, ...response.data.result] });
        })
    }

    generatePdf() {
        const title = this.state.current_leshoz_id + '_' + this.state.current_forestry
        const premidhtml = document.getElementById("print").outerHTML;
        // const midhtml = premidhtml.slice(0, 20) + " style='page-break-before: always'" + premidhtml.slice(20)
        // const re = /id=\"break\"/gi;
        const re = /id=\"stand-break\"/gi;
        // const re2 = /id=\"rotate\"/gi;
        const premidhtml2 = premidhtml.replace(re, "style='page-break-before: avoid'");
        // const premidhtml3 = premidhtml2.replace(re2, "style='display:block; max-width:35px; max-height:35px;word-wrap:break-word;'");
        const premidhtml3 = premidhtml2.replace("id=\"break\"", "");
        const midhtml = premidhtml3 + '<style>th {text-align: center;padding:5px 5px} td {text-align:center; padding: 0px 2px} #break {page-break-before: always} #rotate{max-width:35px; max-height:35px;word-wrap:break-word;}</style>'
        this.openWindowWithPost("https://forest.caiag.kg/ru/rent/main/tompdfla3", {
            html: midhtml,
            title: title,
            "<?=Yii::$app->request->csrfParam?>":
                "<?=Yii::$app->request->csrfToken?>"
        });
    }
    // display: block;-webkit-transform: rotate(90deg);    -moz-transform: rotate(90deg);    -o-transform: rotate(90deg);    -ms-transform: rotate(90deg);    transform: rotate(90deg);    font-weight: normal;    max-width: 45px;    text-align: center;    padding-right: 1px;    margin-top: -45px

    openWindowWithPost(url, data) {
        var form = document.createElement("form");
        form.target = "_blank";
        form.method = "POST";
        form.action = url;
        form.style.display = "none";

        for (var key in data) {
            var input = document.createElement("input");
            input.type = "hidden";
            input.name = key;
            input.value = data[key];
            form.appendChild(input);
        }

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    }

    render() {
        return (

            <div className="App">
                <table>
                    <tbody>
                        <tr>
                            <th className='Header'>{this.state.dictionary.oblast}</th>
                            <th className='Header'>{this.state.dictionary.leshoz}</th>
                            <th className='Header'>{this.state.dictionary.forestry}</th>
                            {/* <th className='Header'>{this.state.dictionary.block}</th> */}
                            <th className='Header'>{this.state.dictionary.cycle}</th>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>
                                <select value={this.state.selectedOblast} onChange={this.refineLeshoz} className='Select'>
                                    <option>
                                        {this.state.dictionary.choose}
                                    </option>
                                    {this.state.oblast.map((oblast) => <option value={oblast.oblast_id} key={oblast.oblast_id.toString()}>{oblast.oblast}</option>)}
                                </select>
                            </td>
                            <td>
                                <select value={this.state.selectedLeshoz} onChange={this.refineForestry} className='Select'>
                                    <option>
                                        {this.state.dictionary.choose}
                                    </option>
                                    {this.state.leshoz.map((leshoz) => <option value={leshoz.leshoz_id} key={leshoz.leshoz_id.toString()}>{leshoz.leshoz}</option>)}
                                </select>
                            </td>
                            <td>
                                <select value={this.state.selectedForestry} onChange={this.refineBlock} className='Select'>
                                    <option>
                                        {this.state.dictionary.choose}
                                    </option>
                                    {this.state.forestry.map((forestry) => <option value={forestry.forestry_num} key={forestry.gid.toString()}>{forestry.forestry}</option>)}
                                </select>
                            </td>
                            {/* <td>
                                <select value={this.state.selectedBlock} onChange={this.refineCycle} className='Select'>
                                    <option>
                                        {this.state.dictionary.choose}
                                    </option>
                                    {this.state.block.map((block) => <option key={block.gid.toString()}>{block.block_num}</option>)}
                                </select>
                            </td> */}
                            <td>
                                <select value={2} onChange={this.selectCycle} className='Select'>
                                    <option>
                                        {this.state.dictionary.choose}
                                    </option>
                                    {this.state.cycle.map((cycle, id) => <option key={id.toString()}>{cycle}</option>)}
                                </select>
                            </td>
                            <td>
                                <button onClick={this.getData}>{this.state.dictionary.getdata}</button>
                            </td>
                            <td>
                                <button onClick={this.generatePdf}>{this.state.dictionary.print}</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {this.state.is_waiting !== null ? <h2>{this.state.dictionary.wait}</h2> : null}
                <div id="print">
                    {this.state.standEstimations.length > 0 ? <h2>{this.state.current_leshoz}</h2> : null}
                    {this.state.standEstimations.length > 0 ? <h2>{this.state.current_forestry}</h2> : null}
                    {/* style={{marginRight: spacing + 'em'}} when using JSX. */}
                    {this.state.standEstimations.map(elem => {
                        let total_block_stands = 0;
                        let total_block_area = 0;
                        return (
                            // <div id='break' key={elem.block_num}>{this.state.dictionary.block} <span>{elem.block_num}</span>
                            <div key={elem.block_num}>
                                <br></br>
                                <div>
                                    {Object.entries(elem.block_data).map(elem2 => {
                                        let total_protect_stands = 0
                                        let total_protect_area = 0;
                                        const id = Object.entries(elem2[1].protectdata)[0]['1'].standestimation_id
                                        return (
                                            <div id='break' key={id}>
                                                <h3 >
                                                    {this.state.dictionary.block} <span>{elem.block_num}</span>
                                                    <br></br>
                                                    {this.state.dictionary.protect}: <span>{elem2[1].protect}</span>
                                                </h3>
                                                <table style={{ borderCollapse: 'collapse' }} cellPadding="0" cellSpacing="0" align="center" border='1'>
                                                    <thead>
                                                        <tr>
                                                            <th rowSpan='2' className='highth' >{this.state.dictionary.stand_num}</th>
                                                            <th rowSpan='2'>{this.state.dictionary.stand_area}</th>
                                                            <th rowSpan='2' id='rotate' text-rotate="90"><span>{this.state.dictionary.exposition}</span></th>
                                                            <th rowSpan='2' id='rotate' text-rotate="90"><span>{this.state.dictionary.land_category}</span></th>
                                                            <th rowSpan='2' id='rotate' text-rotate="90"><span>{this.state.dictionary.forest_origin}</span></th>
                                                            <th rowSpan='2' id='rotate' text-rotate="90"><span>{this.state.dictionary.forest_type}</span></th>
                                                            <th rowSpan='2' id='rotate' text-rotate="90"><span>{this.state.dictionary.age_class}</span></th>
                                                            <th rowSpan='2'>{this.state.dictionary.forest_composition}</th>
                                                            <th colSpan='4' style={{ maxHeight: '5' }} >{this.state.dictionary.stand_code}</th>
                                                            <th rowSpan='2' id='rotate' text-rotate="90"><span>{this.state.dictionary.layerage}</span></th>
                                                            <th rowSpan='2' id='rotate' text-rotate="90"><span>{this.state.dictionary.renewal_state}</span></th>
                                                            <th rowSpan='2' id='rotate' text-rotate="90"><span>{this.state.dictionary.underbrush}</span></th>
                                                            <th rowSpan='2' id='rotate' text-rotate="90"><span>{this.state.dictionary.forestuseform}</span></th>
                                                            <th rowSpan='2' id='rotate' text-rotate="90"><span>{this.state.dictionary.forestusekind}</span></th>
                                                            <th rowSpan='2' id='rotate' text-rotate="90"><span>{this.state.dictionary.burl}</span></th>
                                                            <th rowSpan='2' id='rotate' text-rotate="90"><span>{this.state.dictionary.economy_state}</span></th>
                                                            <th rowSpan='2' id='rotate' text-rotate="90"><span>{this.state.dictionary.exploitation_category}</span></th>
                                                            <th rowSpan='2' id='rotate' text-rotate="90"><span>{this.state.dictionary.stability}</span></th>
                                                            <th rowSpan='2' id='rotate' text-rotate="90"><span>{this.state.dictionary.marketability}</span></th>
                                                            <th rowSpan='2' id='rotate' text-rotate="90"><span>{this.state.dictionary.sanitary_state}</span></th>
                                                            <th rowSpan='2' id='rotate' text-rotate="90"><span>{this.state.dictionary.clutter}</span></th>
                                                            <th rowSpan='2' id='rotate' text-rotate="90"><span>{this.state.dictionary.wood_volume}</span></th>
                                                            <th colSpan='7' style={{ maxHeight: '5' }} >{this.state.dictionary.actions}</th>
                                                        </tr>
                                                        <tr>
                                                            <th><span>{this.state.dictionary.evolution_stage}</span></th>
                                                            <th><span>{this.state.dictionary.forest_kind}</span></th>
                                                            <th><span>{this.state.dictionary.crown_density}</span></th>
                                                            <th><span>{this.state.dictionary.main_specie}</span></th>

                                                            <th>{this.state.dictionary.action_type}</th>
                                                            <th>{this.state.dictionary.urgency}</th>
                                                            <th>{this.state.dictionary.percent}</th>
                                                            <th>{this.state.dictionary.act_forestuseform}</th>
                                                            <th>{this.state.dictionary.act_forestusekind}</th>
                                                            <th>{this.state.dictionary.soil}</th>
                                                            <th>{this.state.dictionary.creation}</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {Object.entries(elem2[1].protectdata).map((elem5, index) => {
                                                            const area = parseFloat(parseFloat(elem5['1'].hectares).toFixed(1))
                                                            // total_protect_area = total_protect_area + parseFloat(this.calc_area(elem5['1'].hectares));
                                                            total_protect_area = total_protect_area + area
                                                            total_protect_stands = total_protect_stands + 1
                                                            // total_block_area = total_block_area + parseFloat(this.calc_area(elem5['1'].hectares));
                                                            total_block_area = total_block_area + area
                                                            total_block_stands = total_block_stands + 1
                                                            this.state.total_forestry_stands = this.state.total_forestry_stands + 1
                                                            this.state.total_forestry_area = this.state.total_forestry_area + area
                                                            let forest_composition = JSON.parse(elem5['1'].forest_composition)
                                                            if (forest_composition === null) {
                                                                forest_composition = []
                                                            }
                                                            const compare = (a, b) => {
                                                                if (a.percent > b.percent) {
                                                                    return -1
                                                                }
                                                                if (a.percent < b.percent) {
                                                                    return 1
                                                                }
                                                                return 0
                                                            }
                                                            forest_composition.sort(compare)
                                                            let forest_use = JSON.parse(elem5['1'].forest_use)
                                                            if (forest_use === null) {
                                                                forest_use = []
                                                            }
                                                            let actions = JSON.parse(elem5['1'].actions)
                                                            if (actions === null) {
                                                                actions = []
                                                            }
                                                            return (
                                                                <React.Fragment key={index.toString()}>
                                                                    <tr>
                                                                        <td>{elem5['1'].stand_num}</td>
                                                                        <td>{parseFloat(elem5['1'].hectares).toFixed(1)}</td>
                                                                        {/* <td>{this.calc_area(elem5['1'].hectares)}</td> */}
                                                                        <td>{this.state.expositionAbbr[elem5['1'].exposition_id - 1]}</td>
                                                                        <td>{elem5['1'].landtype_code}</td>
                                                                        <td>{elem5['1'].forestorigin_id}</td>
                                                                        <td>{elem5['1'].foresttype_code}</td>
                                                                        <td>{elem5['1'].ageclass_id}</td>
                                                                        <td>
                                                                            {forest_composition.map((elem3, index) => {
                                                                                let percent = ''
                                                                                if (elem3.percent === 0) {
                                                                                    percent = '+'
                                                                                }
                                                                                else {
                                                                                    percent = elem3.percent / 10
                                                                                }
                                                                                if (elem3.plan_fact === 1 && elem.standestimation_id === elem3.standestimation_id) {
                                                                                    if (elem3.wood_id == 98) {
                                                                                        elem3.wood_id = 63
                                                                                    }
                                                                                    if (elem3.wood_id == 99) {
                                                                                        elem3.wood_id = 64
                                                                                    }
                                                                                    return (
                                                                                        <span key={index.toString()}>{percent}{this.state.woodSpecies[elem3.wood_id - 1].woodshortname}</span>
                                                                                    )
                                                                                }

                                                                            })}
                                                                        </td>
                                                                        {/* <td>{elem.stand_code}</td> */}
                                                                        <td align='center'>{elem5['1'].evolutionstage_id}</td>
                                                                        <td>{elem5['1'].forestkind_id}</td>
                                                                        <td>{elem5['1'].crowndensity_id}</td>
                                                                        <td>{elem5['1'].mainspeciespart_id}</td>
                                                                        <td>{elem5['1'].layerage_id}</td>
                                                                        <td>{elem5['1'].renewalstate_code}</td>
                                                                        <td>{elem5['1'].underbrush_id}</td>
                                                                        <td>{elem5['1'].forestuseorgform_id}</td>
                                                                        <td>
                                                                            {forest_use.map(elem2 => {
                                                                                if (elem2.plan_fact === 1) {
                                                                                    return (
                                                                                        elem2.usetype_id
                                                                                    )
                                                                                }

                                                                            })}
                                                                        </td>
                                                                        <td>{elem5['1'].burl_id}</td>
                                                                        <td>{elem5['1'].economy_id}</td>
                                                                        <td>{elem5['1'].exploitationcat_id}</td>
                                                                        <td>{elem5['1'].stability_id}</td>
                                                                        <td>{elem5['1'].marketability_id}</td>
                                                                        <td>{elem5['1'].sanitarystate_id}</td>
                                                                        <td>{elem5['1'].clutter}</td>
                                                                        <td>{this.get_wood_volume(elem5['1'].standestimation_id)}</td>
                                                                        <td>{actions.map(standEstimation => {
                                                                            // if (standEstimation.length > 0) {
                                                                            if (this.state.notSimpleAction.indexOf(standEstimation.actiontype_id) === -1) {
                                                                                return (
                                                                                    standEstimation.actiontype_id
                                                                                )
                                                                            }
                                                                        })}</td>
                                                                        <td>{actions.map(standEstimation => {
                                                                            if (this.state.notSimpleAction.indexOf(standEstimation.actiontype_id) === -1) {
                                                                                return (
                                                                                    standEstimation.actionurgency_id
                                                                                )
                                                                            }
                                                                            // else {
                                                                            //     return (
                                                                            //         standEstimation.actionurgency_id
                                                                            //     )
                                                                            // }
                                                                        })}</td>
                                                                        <td>{actions.map(standEstimation => {
                                                                            if (this.state.notSimpleAction.indexOf(standEstimation.actiontype_id) === -1) {
                                                                                return (
                                                                                    standEstimation.intensity
                                                                                )
                                                                            }
                                                                            // else {
                                                                            //     return (
                                                                            //         standEstimation.intensity
                                                                            //     )
                                                                            // }
                                                                        })}</td>
                                                                        <td>{elem5['1'].planuseorgform1_id}</td>
                                                                        <td>
                                                                            {forest_use.map(elem2 => {
                                                                                if (elem2.plan_fact === 0) {
                                                                                    return (
                                                                                        elem2.usetype_id
                                                                                    )
                                                                                }

                                                                            })}
                                                                        </td>
                                                                        <td>{actions.map(standEstimation => {
                                                                            if (this.state.tillage.indexOf(standEstimation.actiontype_id) > -1) {
                                                                                return (
                                                                                    standEstimation.actiontype_id - 57
                                                                                )
                                                                            }
                                                                        })}</td>
                                                                        <td>{actions.map(standEstimation => {
                                                                            if (this.state.creationType.indexOf(standEstimation.actiontype_id) > -1) {
                                                                                return (
                                                                                    standEstimation.actiontype_id - 63
                                                                                )
                                                                            }
                                                                        })}</td>
                                                                    </tr>
                                                                    <tr id='stand-break'>
                                                                        <td colSpan='6'>{this.state.dictionary.stand_state}:</td>
                                                                        <td colSpan='2'>{elem5['1'].addinfo}</td>
                                                                    </tr>
                                                                    <tr id='stand-break'>
                                                                        <td colSpan='6'>{this.state.dictionary.purpose}:</td>
                                                                        {this.state.purpose[elem5['1'].purpose_id - 1] !== undefined &&
                                                                            <td colSpan='2'>{this.state.purpose[elem5['1'].purpose_id - 1].purpose_ru}</td>
                                                                        }


                                                                        {/* <td colSpan='4'>{this.state.purpose[elem.purpose_id + 2].purpose_ru}</td>
                                    {unreadMessages.length > 0 &&
                                        <h2>
                                            У вас {unreadMessages.length} непрочитанных сообщений.
                                        </h2>
                                    } */}
                                                                        {/* <td colSpan='4'>{}</td> */}

                                                                        {/* <td colSpan='4'>{this.state.purpose.map(purpose => {
                                    if (elem.purpose_id === null) {
                                        return (
                                            ''
                                        )
                                    }
                                    else {
                                        
                                    }
                                })}</td> */}

                                                                    </tr>

                                                                </React.Fragment>
                                                            )

                                                        })}
                                                    </tbody>
                                                </table>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td><h3>{this.state.dictionary.total_protect}:</h3></td>
                                                            <td><h3>{this.state.dictionary.stands}:</h3></td>
                                                            <td><h3>{total_protect_stands},</h3></td>
                                                            <td><h3>{this.state.dictionary.area}:</h3></td>
                                                            <td><h3>{total_protect_area.toFixed(1)}</h3></td>
                                                        </tr>
                                                    </tbody>

                                                </table>
                                                {/* <div>
                                                    <h3>{this.state.dictionary.total_protect}:</h3>
                                                    <div>
                                                        <div>{total_protect_stands}</div>
                                                        <div>{this.calc_area(total_protect_area)}</div>
                                                    </div>
                                                    <br></br>

                                                </div> */}
                                            </div>
                                        )
                                    })}
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td><h3>{this.state.dictionary.total_block}:</h3></td>
                                                <td><h3>{this.state.dictionary.stands}:</h3></td>
                                                <td><h3>{total_block_stands},</h3></td>
                                                <td><h3>{this.state.dictionary.area}:</h3></td>
                                                <td><h3>{total_block_area.toFixed(1)}</h3></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    {/* <div>
                                        <h3>{this.state.dictionary.total_block}:</h3>
                                        <div>
                                            <div>{total_block_stands}</div>
                                            <div>{(total_block_area)}</div>
                                        </div>
                                        <br></br>
                                    </div> */}

                                </div>
                            </div>

                        )
                    })}

                    {this.state.standEstimations.length > 0 ?
                        <table>
                            <tbody>
                                <tr>
                                    <td><h3>{this.state.dictionary.total_forestry}:</h3></td>
                                    <td><h3>{this.state.dictionary.stands}:</h3></td>
                                    <td><h3>{this.state.total_forestry_stands},</h3></td>
                                    <td><h3>{this.state.dictionary.area}:</h3></td>
                                    <td><h3>{this.state.total_forestry_area.toFixed(1)}</h3></td>
                                </tr>
                            </tbody>
                        </table> : null}


                </div>
            </div>
        )
    }
}

export default Stands;