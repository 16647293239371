import React, { Component } from 'react';
// import axios from "axios";
// import { Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';
// import html2pdf from 'html2pdf.js'
// import printJS from 'print-js'
// import ReactPDF from '@react-pdf/renderer';
// import { Preview, print } from 'react-html2pdf';
import './App.css';
// import Header from './Header'
import Stands from './Stands'


// const serverAdress = 'http://localhost:5000'
// const serverAdress = 'http://192.168.20.228:5000'


class App extends Component {


  render() {
    // var toExport = document.getElementById('root')
    // var options = {
    //   margin: 1,
    //   filename: 'myfile.pdf',
    //   image: { type: 'jpeg', quality: 0.98 },
    //   html2canvas: { scale: 1 },
    //   jsPDF: { unit: 'mm', format: 'a3', orientation: 'landscape' }
    // }
    return (
      <div>
        {/* <button type="submit" onClick={() => { html2pdf().set(options).from(toExport).save() }}>Экспорт в PDF</button> */}
        {/* <button type="submit" onClick={() => { printJS('root', 'html') }}>Экспорт в PDF</button> */}

         

        <Stands />
        {/* <button onClick={html2pdf(toExport)}>Экспорт в PDF</button> */}

      </div>
    )
  }
}
// ReactPDF.render(<App />, `${__dirname}/example.pdf`);
// ReactPDF.render(<Document />, `${__dirname}/example.pdf`);


export default App;